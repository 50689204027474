import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import notFound from '../views/notFound.vue'
import NProgress from 'vue-nprogress'
import {store} from "@/store/store";

Vue.use(NProgress)
const nprogress = new NProgress()
const options = {
    speed: 1000,
    latencyThreshold: 400, // Number of ms before progressbar starts showing, default: 100,
    router: true, // Show progressbar when navigating routes, default: true
    http: true, // Show progressbar when doing Vue.http, default: true

};

Vue.use(nprogress, options)
// global.jQuery = require('jquery');
// var $ = global.jQuery;
// window.$ = $;

Vue.use(VueRouter)

const routes = [
    // {
    //     path: '/', redirect: '/ua'
    // },

    {
        path: '/:lang(ua|ru)?',
        name: 'home',
        component: Home,
        meta: {gtm: 'Home'}
    },

    {
        path: '/:lang(ua|ru)/show',
        name: 'show',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "show" */ '../views/show.vue'),
        meta: {gtm: 'show'},
        children: [
            {
                path: ':channelSlug',
                name: 'sortedShow',
                component: () => import(/* webpackChunkName: "sortedShow" */ '../views/sortedShow.vue'),
                meta: {gtm: 'showchannel'}
            },
            {
                path: 'genre/:genreSlug',
                name: 'sortedGenre',
                component: () => import(/* webpackChunkName: "sortedShow" */ '../views/sortedShow.vue'),
                meta: {gtm: 'showgenre'}
            },
        ]
    },

    {
        path: '/:lang(ua|ru)/serials',
        name: 'serials',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "serials" */ '../views/serials.vue'),
        meta: {gtm: 'serials'},
        children: [
            {
                path: ':channelSlug',
                name: 'sortedSerials',
                component: () => import(/* webpackChunkName: "sortedSerials" */ '../views/sortedShow.vue'),
                meta: {gtm: 'serialschannel'}
            },
            {
                path: 'genre/:genreSlug',
                name: 'sortedGenreSerials',
                component: () => import(/* webpackChunkName: "sortedSerials" */ '../views/sortedShow.vue'),
                meta: {gtm: 'serialsgenreserials'}
            },
        ]
    },

    {
        path: '/:lang(ua|ru)/film',
        redirect: '/:lang(ua|ru)/documentaries'
    },
    {
        path: '/:lang(ua|ru)/film/:channelSlug/:videoSlug',
        redirect: '/:lang(ua|ru)/documentaries/:channelSlug/:videoSlug'
    },
    {
        path: '/:lang(ua|ru)/documentaries',
        name: 'films',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "serials" */ '../views/films.vue'),
        meta: {gtm: 'films'},
        children: [
            {
                path: ':channelSlug',
                name: 'sortedFilms',
                component: () => import(/* webpackChunkName: "sortedSerials" */ '../views/sortedFilms.vue'),
                meta: {gtm: 'filmschannel'}
            },
        ]
    },
    {
        path: '/:lang(ua|ru)/documentaries/:channelSlug/:videoSlug',
        name: 'videoFilmPage',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "videoFilmPage" */ '../views/videoFilmPage.vue'),
        meta: {gtm: 'videoFilmPage'}
    },
    {
        path: '/:lang(ua|ru)/:typeSlug(show|serials)/:channelSlug/:projectSlug',
        name: 'projectPage',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "projectPage" */ '../views/projectPage.vue'),
        meta: {gtm: 'projectPage'}
    },
    {
        path: '/:lang(ua|ru)/:typeSlug/:channelSlug/:projectSlug/person',
        name: 'personList',
        component: () => import(/* webpackChunkName: "personList" */ '../views/personsList.vue'),
        meta: {gtm: 'personsList'}
    },
    {
        path: '/:lang(ua|ru)/:typeSlug/:channelSlug/:projectSlug/:seasonSlug/person',
        name: 'personListSeasons',
        component: () => import(/* webpackChunkName: "personListSeasons" */ '../views/personsListSeasons.vue'),
        meta: {gtm: 'personsListSeasons'}
    },
    {
        path: '/:lang(ua|ru)/:typeSlug(show|serials)/:channelSlug/:projectSlug/:seasonSlug',
        name: 'seasonPage',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "seasonPage" */ '../views/seasonPage.vue'),
        meta: {gtm: 'seasonPage'},
    },
    {
        path: '/:lang(ua|ru)/:typeSlug(show|serials)/:channelSlug/:projectSlug/:seasonSlug/:videoSlug',
        name: 'videoPage',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "videoPage" */ '../views/videoPage.vue'),
        meta: {gtm: 'videoPage'}
    },
    {
        path: '/:lang(ua|ru)/person/:personSlug',
        name: 'singlePerson',
        component: () => import(/* webpackChunkName: "singlePerson" */ '../views/person.vue'),
        meta: {gtm: 'person'}
    },
    {
        path: '/:lang(ua|ru)/agreement',
        name: 'agreement',
        component: () => import(/* webpackChunkName: "agreement" */ '../views/agreement.vue'),
        meta: {gtm: 'agreement'}
    },
    {
        path: '/:lang(ua|ru)/contacts',
        name: 'contacts',
        component: () => import(/* webpackChunkName: "contacts" */ '../views/contacts.vue'),
        meta: {gtm: 'contacts'}
    },
    {
        path: '/:lang(ua|ru)/search',
        name: 'search',
        component: () => import(/* webpackChunkName: "search" */ '../views/search.vue'),
        meta: {gtm: 'search'}
    },
    {
        path: '/:lang(ua|ru)/advertisment',
        name: 'advertisment',
        component: () => import(/* webpackChunkName: "advertisement" */ '../views/advertisment.vue'),
        meta: {gtm: 'advertisment'}
    },
    {
        path: '/:lang(ua|ru)/oficzijni-pravyla-provedennya-aktyvacziyi-mastershef',
        name: 'oficzijni-pravyla-provedennya-aktyvacziyi-mastershef',
        component: () => import(/* webpackChunkName: "advertisement" */ '../views/oficzijni-pravyla-provedennya-aktyvacziyi-mastershef.vue'),
        meta: {gtm: 'oficzijni-pravyla-provedennya-aktyvacziyi-mastershef'}
    },
    {
        path: '/:lang(ua|ru)/faq',
        name: 'faq',
        component: () => import(/* webpackChunkName: "faq" */ '../views/faq.vue'),
        meta: {gtm: 'faq'}
    },
    {
        path: '/:lang(ua|ru)/preferences',
        name: 'MyChoice',
        component: () => import(/* webpackChunkName: "MyChoice" */ '../views/my_choice.vue'),
        meta: {gtm: 'my_choice'}
    },
    {
        path: '/:lang(ua|ru)/register',
        name: 'register',
        component: () => import(/* webpackChunkName: "register" */ '../views/register_auth.vue'),
        meta: {gtm: 'register_auth'}
    },
    {
        path: '/:lang(ua|ru)/restore_pass',
        name: 'restore_pass',
        component: () => import(/* webpackChunkName: "help" */ '../views/forgot_pass.vue'),
        meta: {gtm: 'forgot_pass'}
    },

    {
        path: '/:lang(ua|ru)/404',
        name: 'notFound',
        component: notFound,
        meta: {gtm: 'notFound'}
    },
    {
        path: '/:lang(ua|ru)?/*',
        beforeEnter() {
            window.location = `/${store.state.lang}/404`
        }
    },
]


const router = new VueRouter({
    mode: 'history',
    nprogress,
    // base: process.env.BASE_URL,
    routes,
})

router.beforeEach((to, from, next) => {

    if (to.params.lang !== 'ua' && to.params.lang !== 'ru') {
        to.params.lang = 'ua'
        if (to.fullPath !== '/') {
            next('ua' + to.fullPath)
        }
    }
    store.state.lang = to.params.lang
    store.dispatch('getHeaderText')
    store.dispatch('getFooterText')
    const html = document.documentElement // returns the html tag
    html.setAttribute('lang', store.state.lang)


    // console.log('to', to)
    // console.log('from', from)
    // console.log('next', next)

    // if(to.name === 'notFound'){
    //     console.log(1)
    // window.location.href = '/' + store.state.lang + '/404'
    // }

    nprogress.start()
    // if (!to.matched.length) {
    //     next('/notFound')
    // } else {
    next()
    // }
})

router.afterEach(() => {
    let pp_gemius_identifier = 'zZBAe_sS85fVbfvgDcVbi.TXzSTpWkLtI3H8rmu11c7.a7';// eslint-disable-line
    pp_gemius_hit('zZBAe_sS85fVbfvgDcVbi.TXzSTpWkLtI3H8rmu11c7.a7');// eslint-disable-line
    window.scroll(0, 0)
    nprogress.done()

})

export default router
